export default {
  methods: {
    // 期間を追加
    addTerm() {
      this.form.countTermList.push({
        countTermStartDate: null,
        countTermEndDate: null,
        alertValue: null,
      });

      //バリデーションも追加
      this.validate.countTermStartDateList.push({ isValid: true, errMsg: "" });
      this.validate.countTermEndDateList.push({ isValid: true, errMsg: "" });
      this.validate.alertValueList.push({ isValid: true, errMsg: "" });
    },

    // 期間を削除
    removeTerm(countTerm, index) {
      this.form.countTermList = this.form.countTermList.filter(
          (ct) => ct != countTerm
      );

      //バリデーションも削除
      this.validate.countTermStartDateList = this.validate.countTermStartDateList.filter(
          (_ct, i) => i != index
      );
      this.validate.countTermEndDateList = this.validate.countTermEndDateList.filter(
          (_ct, i) => i != index
      );
      this.validate.alertValueList = this.validate.alertValueList.filter(
          (_ct, i) => i != index
      );
    },
  },
};
